import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import { AddTeamMemberState } from 'src/utils/typedefs/team'
import FormInputLabel from 'src/components/form-info-label/form-info-label'
import { allRoles } from 'src/utils/typedefs/role'

interface TeamRolesAndResponsibilitiesInfoProps {
  values: AddTeamMemberState
}

const TeamRolesAndResponsibilitiesInfo: React.FC<TeamRolesAndResponsibilitiesInfoProps> = ({
  values
}) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      {allRoles
        .filter(role => values.rolesAndResponsibilities[role.name])
        .map(role => (
          <Grid key={role.id} item xs={4}>
            <FormInputLabel className="" label={t('name')}>
              {t(role.name)}
            </FormInputLabel>
            <FormInputLabel className="" label={t('status')}>
              {t(role.status)}
            </FormInputLabel>
            <FormInputLabel className="" label={t('description')}>
              {t(role.description)}
            </FormInputLabel>
          </Grid>
        ))}
    </Grid>
  )
}

export default TeamRolesAndResponsibilitiesInfo
