import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import FormInputLabel from 'src/components/form-info-label/form-info-label'
import { AddTeamMemberState } from 'src/utils/typedefs/team'

interface TeamContactDetailsInfoProps {
  values: AddTeamMemberState
}

const TeamContactDetailsInfo: React.FC<TeamContactDetailsInfoProps> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('first name')}>
          {values.contactDetails.contact.firstName}
        </FormInputLabel>
      </Grid>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('last name')}>
          {values.contactDetails.contact.lastName}
        </FormInputLabel>
      </Grid>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('email')}>
          {values.contactDetails.contact.email}
        </FormInputLabel>
      </Grid>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('gender')}>
          {t(values.contactDetails.contact.gender.toString())}
        </FormInputLabel>
      </Grid>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('date of birth')}>
          {values.contactDetails.contact.dateOfBirth
            ? t('dob format', { dob: values.contactDetails.contact.dateOfBirth })
            : null}
        </FormInputLabel>
      </Grid>
      <Grid item xs={4}>
        <FormInputLabel className="" label={t('phone')}>
          {values.contactDetails.contact.phone}
        </FormInputLabel>
      </Grid>
    </Grid>
  )
}

export default TeamContactDetailsInfo
