import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import FormInputLabel from 'src/components/form-info-label/form-info-label'
import { AddTeamMemberState } from 'src/utils/typedefs/team'
import { allAdminRights } from 'src/utils/typedefs/role'

interface TeamAdminRightsInfoProps {
  values: AddTeamMemberState
}

const TeamAdminRightsInfo: React.FC<TeamAdminRightsInfoProps> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <Grid container>
      {allAdminRights
        .filter(adminRight => values.adminRights[adminRight.name])
        .map(adminRight => (
          <Grid key={adminRight.id} item xs={4}>
            <FormInputLabel className="" label={t('name')}>
              {t(adminRight.name)}
            </FormInputLabel>
          </Grid>
        ))}
    </Grid>
  )
}

export default TeamAdminRightsInfo
