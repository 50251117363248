import React from 'react'
import { FastField, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import * as styles from 'src/components/team-admin-rights-form/team-admin-rights-form.module.less'
import InputLabel from 'src/components/input-label/input-label'
import CheckboxLabels from 'src/components/checkbox/checkbox'
import FormContainer from 'src/components/form-container/form-container'
import FormNextStepButton from 'src/components/form-next-step-button/form-next-step-button'
import TeamAdminRightsInfo from 'src/components/team-admin-rights-info/team-admin-rights-info'
import {
  AddTeamMemberActiveSteps,
  AddTeamMemberState,
  AddTeamMemberVisitedSteps
} from 'src/utils/typedefs/team'
import { allAdminRights } from 'src/utils/typedefs/role'
import FormErrorMessage from 'src/components/form-error-message/form-error-message'

interface TeamAdminRightsFormProps {
  formikProps: FormikProps<AddTeamMemberState>
  step: AddTeamMemberActiveSteps
  visitedSteps: AddTeamMemberVisitedSteps
  setStep: (step: AddTeamMemberActiveSteps, visitedSteps: AddTeamMemberVisitedSteps) => void
}

const TeamAdminRightsForm: React.FC<TeamAdminRightsFormProps> = ({
  formikProps,
  step,
  visitedSteps,
  setStep
}) => {
  const { t } = useTranslation()

  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched: { adminRights: touched },
    errors: { adminRights: errors }
  } = formikProps

  const handleSetNextStep = () => {
    setStep(AddTeamMemberActiveSteps.Done, {
      ...visitedSteps,
      adminRights: true
    })
  }

  const handleSetThisStep = () => {
    setStep(AddTeamMemberActiveSteps.AdminRights, {
      ...visitedSteps,
      adminRights: true
    })
  }

  return (
    <FormContainer
      headingText={t('admin rights')}
      displayEditButton={step !== AddTeamMemberActiveSteps.AdminRights && visitedSteps.adminRights}
      setThisStep={handleSetThisStep}
    >
      {step === AddTeamMemberActiveSteps.AdminRights ? (
        <>
          <InputLabel className={styles.membershipLabel}>{t('admin rights')}</InputLabel>
          {allAdminRights.map(adminRight => (
            <FastField
              key={adminRight.id}
              field={{
                name: `adminRights[${adminRight.name}]`,
                value: values.adminRights[adminRight.name]
              }}
              label={t(adminRight.name)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              component={CheckboxLabels}
            />
          ))}
          {errors && touched && <FormErrorMessage message={errors.toString()} />}
          <FormNextStepButton disabled={!!errors || !touched} buttonText={t('continue')} handleSetNextStep={handleSetNextStep} />
        </>
      ) : (
        visitedSteps.adminRights && <TeamAdminRightsInfo values={values} />
      )}
    </FormContainer>
  )
}

export default TeamAdminRightsForm
