// extracted by mini-css-extract-plugin
export var adornmentIcon = "team-contact-details-module--adornment-icon--hKdlr";
export var bodyBase = "team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodyLarge = "team-contact-details-module--body-large--wTjwD team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodyLargeBold = "team-contact-details-module--body-large-bold--NriJ6 team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodyRegular = "team-contact-details-module--body-regular--JIyQc team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodyRegularBold = "team-contact-details-module--body-regular-bold--OkgvC team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodySmall = "team-contact-details-module--body-small--4FZ01 team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var bodySmallBold = "team-contact-details-module--body-small-bold--LHu+l team-contact-details-module--body-base--mnSfp team-contact-details-module--site-font--rA8Kf";
export var borderTop = "team-contact-details-module--border-top--IyMtv";
export var breakWordContainer = "team-contact-details-module--break-word-container--1BbyE";
export var buttonIconBase = "team-contact-details-module--button-icon-base--pLFch";
export var clickLink = "team-contact-details-module--click-link--4xcMu";
export var dropdownBase = "team-contact-details-module--dropdown-base--rBhjM";
export var dropdownSelectBase = "team-contact-details-module--dropdown-select-base--99bPW team-contact-details-module--text-input--ll8Kz";
export var flexCol = "team-contact-details-module--flex-col--Ew4Kg";
export var formErrorMessage = "team-contact-details-module--form-error-message--Ribvz";
export var h3 = "team-contact-details-module--h3--fPmR+";
export var h4 = "team-contact-details-module--h4--fpctR";
export var hoverLink = "team-contact-details-module--hover-link--nVLi-";
export var hoverRow = "team-contact-details-module--hover-row--l8lak";
export var membershipContainer = "team-contact-details-module--membership-container--y-L9I team-contact-details-module--flex-col--Ew4Kg team-contact-details-module--primary-border--D6yoD";
export var membershipHeader = "team-contact-details-module--membership-header--KgzQP";
export var membershipHeading = "team-contact-details-module--membership-heading--TAMqX";
export var membershipLabel = "team-contact-details-module--membership-label--48qE5";
export var moreFiltersBorderClass = "team-contact-details-module--more-filters-border-class--NpRTk";
export var pageBg = "team-contact-details-module--page-bg--lzH0A";
export var pointer = "team-contact-details-module--pointer--DBB1u";
export var primaryBorder = "team-contact-details-module--primary-border--D6yoD";
export var shadowBoxLight = "team-contact-details-module--shadow-box-light--vGl3c";
export var siteFont = "team-contact-details-module--site-font--rA8Kf";
export var slideDownAndFade = "team-contact-details-module--slideDownAndFade--fthz6";
export var slideLeftAndFade = "team-contact-details-module--slideLeftAndFade--VeQ9v";
export var slideRightAndFade = "team-contact-details-module--slideRightAndFade--eDDxR";
export var slideUpAndFade = "team-contact-details-module--slideUpAndFade--V1WZK";
export var statusDecoration = "team-contact-details-module--status-decoration--2eEW+";
export var textInput = "team-contact-details-module--text-input--ll8Kz";
export var textInverted = "team-contact-details-module--text-inverted--hv5n1";
export var textMediumDark = "team-contact-details-module--text-medium-dark--QcnR0";
export var tooltipFont = "team-contact-details-module--tooltipFont--kGouA";
export var unstyledButton = "team-contact-details-module--unstyled-button--mizR8";