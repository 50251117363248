import React, { useState } from 'react'
import { FastField, FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import * as styles from 'src/components/team-roles-and-responsibilities-form/team-roles-and-responsibilities-form.module.less'
import InputLabel from 'src/components/input-label/input-label'
import CheckboxLabels from 'src/components/checkbox/checkbox'
import Button from 'src/components/button/button'
import CustomDialog from 'src/components/custom-dialog/custom-dialog'
import AddRoleDialog from 'src/components/role-dialog/add-role-dialog'
import FormContainer from 'src/components/form-container/form-container'
import FormNextStepButton from 'src/components/form-next-step-button/form-next-step-button'
import TeamRolesAndResponsibilitiesInfo from 'src/components/team-roles-and-responsibilities-info/team-roles-and-responsibilities-info'
import Icon from 'src/components/icon/icon'
import {
  AddTeamMemberActiveSteps,
  AddTeamMemberState,
  AddTeamMemberVisitedSteps
} from 'src/utils/typedefs/team'
import { allRoles } from 'src/utils/typedefs/role'
import FormErrorMessage from 'src/components/form-error-message/form-error-message'

interface TeamRolesAndResponsibilitiesFormProps {
  formikProps: FormikProps<AddTeamMemberState>
  step: AddTeamMemberActiveSteps
  visitedSteps: AddTeamMemberVisitedSteps
  setStep: (step: AddTeamMemberActiveSteps, visitedSteps: AddTeamMemberVisitedSteps) => void
}

const TeamRolesAndResponsibilitiesForm: React.FC<TeamRolesAndResponsibilitiesFormProps> = ({
  formikProps,
  step,
  visitedSteps,
  setStep
}) => {
  const { t } = useTranslation()
  const [openAddRole, setOpenAddRole] = useState(false)

  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched: { rolesAndResponsibilities: touched },
    errors: { rolesAndResponsibilities: errors }
  } = formikProps

  const roleFormikProps = formikProps['rolesAndResponsibilities']

  const handleSetNextStep = () => {
    setStep(AddTeamMemberActiveSteps.AdminRights, {
      ...visitedSteps,
      adminRights: true
    })
  }

  const handleSetThisStep = () => {
    setStep(AddTeamMemberActiveSteps.RolesAndResponsibilities, {
      ...visitedSteps,
      rolesAndResponsibilities: true
    })
  }

  const handleAddRoleClick = () => {
    setOpenAddRole(true)
  }

  const handleAddRoleClose = () => {
    setOpenAddRole(false)
  }

  return (
    <FormContainer
      headingText={t('roles and responsibilities')}
      displayEditButton={
        step !== AddTeamMemberActiveSteps.RolesAndResponsibilities &&
        visitedSteps.rolesAndResponsibilities
      }
      setThisStep={handleSetThisStep}
    >
      {step === AddTeamMemberActiveSteps.RolesAndResponsibilities ? (
        <>
          <InputLabel className={styles.membershipLabel}>{t('roles')}</InputLabel>
          {/* TODO: based on the UI - the Role types will also come from graphql apollo */}
          {allRoles.map(role => (
            <FastField
              key={role.id}
              field={{
                name: `rolesAndResponsibilities[${role.name}]`,
                value: values.rolesAndResponsibilities[role.name]
              }}
              label={t(role.name)}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              component={CheckboxLabels}
            />
          ))}
          {errors && touched && <FormErrorMessage message={errors.toString()} />}
          <CustomDialog
            open={openAddRole}
            onClose={handleAddRoleClose}
            title={t('add role')}
            content={<AddRoleDialog />}
            actions={
              <>
                <Button onClick={handleAddRoleClose}>{t('close')}</Button>
                <Button disabled={!!errors || !touched}>{t('confirm')}</Button>
              </>
            }
          />
          <div className={styles.addRoleButton}>
            <Button size="md" type="button" onClick={handleAddRoleClick}>
              {<Icon name="sm-add" light={true} />} {t('add new role')}
            </Button>
          </div>
          <FormNextStepButton disabled={!!errors || !touched} buttonText={t('continue')} handleSetNextStep={handleSetNextStep} />
        </>
      ) : (
        visitedSteps.rolesAndResponsibilities && (
          <TeamRolesAndResponsibilitiesInfo values={values} />
        )
      )}
    </FormContainer>
  )
}

export default TeamRolesAndResponsibilitiesForm
