import React from 'react'

import Grid from '@material-ui/core/Grid'

import * as styles from 'src/components/form-container/form-container.module.less'
import Edit from 'src/components/edit/edit'

interface FormContainerProps {
  headingText: string
  displayEditButton?: boolean
  setThisStep?: () => void
}

const FormContainer: React.FC<FormContainerProps> = ({
  children,
  headingText,
  displayEditButton,
  setThisStep
}) => {
  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item className={styles.formHeaderContainer}>
        <h1 className={styles.formHeading}>{headingText}</h1>
        {displayEditButton && <Edit action={setThisStep} />}
      </Grid>
      {children}
    </Grid>
  )
}

export default FormContainer
