// extracted by mini-css-extract-plugin
export var bodyBase = "team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodyLarge = "team-admin-rights-form-module--body-large--Bj3a3 team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodyLargeBold = "team-admin-rights-form-module--body-large-bold--BSv8D team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodyRegular = "team-admin-rights-form-module--body-regular--4adyd team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodyRegularBold = "team-admin-rights-form-module--body-regular-bold--MaZGO team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodySmall = "team-admin-rights-form-module--body-small--lpbq9 team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var bodySmallBold = "team-admin-rights-form-module--body-small-bold--V+YYq team-admin-rights-form-module--body-base--RBq2e team-admin-rights-form-module--site-font--EleFz";
export var borderTop = "team-admin-rights-form-module--border-top--W09+3";
export var breakWordContainer = "team-admin-rights-form-module--break-word-container--aYcOV";
export var buttonIconBase = "team-admin-rights-form-module--button-icon-base--ja3Yt";
export var clickLink = "team-admin-rights-form-module--click-link--T+Q48";
export var dropdownBase = "team-admin-rights-form-module--dropdown-base--XzJBO";
export var dropdownSelectBase = "team-admin-rights-form-module--dropdown-select-base--i9RNe team-admin-rights-form-module--text-input--KjmF1";
export var flexCol = "team-admin-rights-form-module--flex-col--S3GML";
export var formErrorMessage = "team-admin-rights-form-module--form-error-message--IonGR";
export var h3 = "team-admin-rights-form-module--h3--D47Wk";
export var h4 = "team-admin-rights-form-module--h4--gvlyM";
export var hoverLink = "team-admin-rights-form-module--hover-link--Q0B8U";
export var hoverRow = "team-admin-rights-form-module--hover-row--siWOk";
export var membershipContainer = "team-admin-rights-form-module--membership-container--vydSG team-admin-rights-form-module--flex-col--S3GML team-admin-rights-form-module--primary-border--FF821";
export var membershipHeader = "team-admin-rights-form-module--membership-header--W91AI";
export var membershipHeading = "team-admin-rights-form-module--membership-heading--1VtGi";
export var membershipLabel = "team-admin-rights-form-module--membership-label---kNHx";
export var moreFiltersBorderClass = "team-admin-rights-form-module--more-filters-border-class--Z8u21";
export var pageBg = "team-admin-rights-form-module--page-bg--y3EQI";
export var pointer = "team-admin-rights-form-module--pointer--JECGw";
export var primaryBorder = "team-admin-rights-form-module--primary-border--FF821";
export var shadowBoxLight = "team-admin-rights-form-module--shadow-box-light--nfSdn";
export var siteFont = "team-admin-rights-form-module--site-font--EleFz";
export var slideDownAndFade = "team-admin-rights-form-module--slideDownAndFade--Hvo0x";
export var slideLeftAndFade = "team-admin-rights-form-module--slideLeftAndFade--yVj0D";
export var slideRightAndFade = "team-admin-rights-form-module--slideRightAndFade--4evpW";
export var slideUpAndFade = "team-admin-rights-form-module--slideUpAndFade--Cugxk";
export var statusDecoration = "team-admin-rights-form-module--status-decoration--YdTTT";
export var textInput = "team-admin-rights-form-module--text-input--KjmF1";
export var textInverted = "team-admin-rights-form-module--text-inverted--3gmRi";
export var textMediumDark = "team-admin-rights-form-module--text-medium-dark--uYosL";
export var tooltipFont = "team-admin-rights-form-module--tooltipFont--W61h9";
export var unstyledButton = "team-admin-rights-form-module--unstyled-button--By14j";