import React from 'react'

import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import TeamAddMember from 'src/components/team-add-member/team-add-member'
import { usePrivateRoute } from 'src/utils/auth'

const AddTeamMember: React.FC = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Add Team Member" />
      <TeamAddMember />
    </Layout>
  )
}

export default AddTeamMember