// extracted by mini-css-extract-plugin
export var addRoleButton = "team-roles-and-responsibilities-form-module--addRoleButton--nIrQY";
export var bodyBase = "team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodyLarge = "team-roles-and-responsibilities-form-module--body-large--zGqe9 team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodyLargeBold = "team-roles-and-responsibilities-form-module--body-large-bold--vyUMr team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodyRegular = "team-roles-and-responsibilities-form-module--body-regular--gnHY2 team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodyRegularBold = "team-roles-and-responsibilities-form-module--body-regular-bold--spnEp team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodySmall = "team-roles-and-responsibilities-form-module--body-small--ZKGJd team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var bodySmallBold = "team-roles-and-responsibilities-form-module--body-small-bold--k8SoT team-roles-and-responsibilities-form-module--body-base--ogSKN team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var borderTop = "team-roles-and-responsibilities-form-module--border-top--rLUsK";
export var breakWordContainer = "team-roles-and-responsibilities-form-module--break-word-container--0KRtv";
export var buttonIconBase = "team-roles-and-responsibilities-form-module--button-icon-base--nyhm1";
export var clickLink = "team-roles-and-responsibilities-form-module--click-link--PPveN";
export var dropdownBase = "team-roles-and-responsibilities-form-module--dropdown-base--lDE3B";
export var dropdownSelectBase = "team-roles-and-responsibilities-form-module--dropdown-select-base--14Udp team-roles-and-responsibilities-form-module--text-input--qf4Gs";
export var flexCol = "team-roles-and-responsibilities-form-module--flex-col--gdsnV";
export var formErrorMessage = "team-roles-and-responsibilities-form-module--form-error-message--PV4dA";
export var h3 = "team-roles-and-responsibilities-form-module--h3--dUdX3";
export var h4 = "team-roles-and-responsibilities-form-module--h4--YmIFY";
export var hoverLink = "team-roles-and-responsibilities-form-module--hover-link--8t1b7";
export var hoverRow = "team-roles-and-responsibilities-form-module--hover-row--rNUsJ";
export var membershipContainer = "team-roles-and-responsibilities-form-module--membership-container--qxlld team-roles-and-responsibilities-form-module--flex-col--gdsnV team-roles-and-responsibilities-form-module--primary-border--4wOJT";
export var membershipHeader = "team-roles-and-responsibilities-form-module--membership-header--Mj3Mq";
export var membershipHeading = "team-roles-and-responsibilities-form-module--membership-heading--nDrzx";
export var membershipLabel = "team-roles-and-responsibilities-form-module--membership-label--LbzLh";
export var moreFiltersBorderClass = "team-roles-and-responsibilities-form-module--more-filters-border-class--B3N4p";
export var pageBg = "team-roles-and-responsibilities-form-module--page-bg---lVj3";
export var pointer = "team-roles-and-responsibilities-form-module--pointer--+1JZy";
export var primaryBorder = "team-roles-and-responsibilities-form-module--primary-border--4wOJT";
export var shadowBoxLight = "team-roles-and-responsibilities-form-module--shadow-box-light--aVC8A";
export var siteFont = "team-roles-and-responsibilities-form-module--site-font--vKdfG";
export var slideDownAndFade = "team-roles-and-responsibilities-form-module--slideDownAndFade--NlX-S";
export var slideLeftAndFade = "team-roles-and-responsibilities-form-module--slideLeftAndFade--J00w7";
export var slideRightAndFade = "team-roles-and-responsibilities-form-module--slideRightAndFade--8PSd1";
export var slideUpAndFade = "team-roles-and-responsibilities-form-module--slideUpAndFade--s-uy2";
export var statusDecoration = "team-roles-and-responsibilities-form-module--status-decoration--QqQZC";
export var textInput = "team-roles-and-responsibilities-form-module--text-input--qf4Gs";
export var textInverted = "team-roles-and-responsibilities-form-module--text-inverted--3ZSop";
export var textMediumDark = "team-roles-and-responsibilities-form-module--text-medium-dark--aqaPb";
export var tooltipFont = "team-roles-and-responsibilities-form-module--tooltipFont--lf7KJ";
export var unstyledButton = "team-roles-and-responsibilities-form-module--unstyled-button--VTAFx";